import { isMobileView, isShowresultPage } from '@utils/toolbox';

export class DateSelector {
	constructor (config) {
		this.html = config.html;
		this.viewType = config.viewType || 'default';
		this.open = false;
		this._callbacks = config.callbacks || {};

		this.selectors = {
			dateSelector: 'abDateSelector',
			dateSelectorVisible: 'abDateSelector--visible',
			dateSelectorHidden: 'abDateSelector--hidden',
			navBtn: 'abTabNav__btn',
			navBtnActive: 'abTabNav__btn--active',
			title: 'abDateSelector__title',
			view: 'abDateSelector__view',
			loader: 'abDateSelector__loader',
			loaderVisible: 'abDateSelector__loader--visible',
			closeBtn: 'abDateSelector__close',
			applyBtn: 'abDateSelector__apply',
			sliderBtns: 'abDateSelector__slider',
			sliderBtnDisabled: 'abDateSelector__slider--disabled'
		};


		this.makeFullScreen();
		this.hideAllViews();
		this.events();

		return this;
	}

	setTitle (html) {
		this.html.querySelector(`.${this.selectors.title}`).innerHTML = html;

		return this;
	}

	makeFullScreen () {
		const isMobile = isMobileView();

		if (isMobile) {
			this.html.style.maxHeight = window.innerHeight + 'px';
		} else {
			this.html.style.maxHeight = '';
		}

		return this;
	}

	hideAllViews () {
		const { view } = this.selectors;
		const views = this.html.querySelectorAll(`.${view}`);

		Array.prototype.forEach.call(views, (v) => {
			const isViewType = v.classList.contains(`${view}--${this.viewType}`);
			v.style.display = (isViewType) ? '' : 'none';
		});

		return this;
	}

	toggleViewType (viewType) {
		this.viewType = viewType;

		const { navBtn, navBtnActive, view } = this.selectors;
		const btns = this.html.querySelectorAll(`.${navBtn}`);
		const views = this.html.querySelectorAll(`.${view}`);

		btns.forEach((b) => {
			const isViewType = b.classList.contains(`${navBtn}--${viewType}`);

			b.classList.remove(navBtnActive);

			if (isViewType) {
				b.classList.add(navBtnActive);
			}
		});

		views.forEach((v) => {
			const isViewType = v.classList.contains(`${view}--${viewType}`);

			v.style.display = 'none';

			if (isViewType) {
				v.style.display = '';
			}
		});

		if (this._callbacks.onViewChange) {
			this._callbacks.onViewChange(viewType);
		}

		return this;
	}

	show (legNb = null) {
		const isMobile = isMobileView();

		if (this._callbacks.onShow) {
			this._callbacks.onShow();
		}

		let topPos = '';

		if (legNb && !isMobile) {
			const legHeight = document.querySelector('.abSearchBox__leg').offsetHeight;
			topPos = (legNb * legHeight - 7) + 'px';
		}

		if (isMobile && isShowresultPage()) {
			topPos = document.querySelector('#ab-showresult-search-box').scrollTop + 'px';
		}

		this.html.style.top = topPos;

		this.html.classList.add(this.selectors.dateSelectorVisible);

		if (isMobile) {
			document.body.style.overflow = 'hidden';
		}

		this.open = true;

		return this;
	}

	blurField () {
		// const isMobile = isMobileView();

		const classes = this.html.classList;
		const { dateSelectorVisible, dateSelectorHidden } = this.selectors;

		this.html.style.top = '';
		classes.remove(dateSelectorVisible);

		classes.remove(dateSelectorHidden);

		document.body.style.overflow = '';

		this.open = false;

		return this;
	}

	reset () {
		if (this._callbacks.onReset) {
			this._callbacks.onReset();
		}
	}

	hide () {
		if (this._callbacks.onHide) {
			this._callbacks.onHide();
		}

		this.blurField();
	}

	showLoader () {
		this.html.querySelector(`.${this.selectors.loader}`)
			.classList.add(this.selectors.loaderVisible);

		return this;
	}

	hideLoader () {
		this.html.querySelector(`.${this.selectors.loader}`)
			.classList.remove(this.selectors.loaderVisible);

		return this;
	}

	events () {
		let resizeTimer;

		window.addEventListener('resize', (event) => {
			if (resizeTimer) {
				cancelAnimationFrame(resizeTimer);
			}

			resizeTimer = requestAnimationFrame(() => this.makeFullScreen());
		});

		this.html.addEventListener('click', (event) => {
			const elem = event.target;

			if (elem.matches(`.${this.selectors.navBtn}`)) {
				if (!elem.classList.contains(this.selectors.navBtnActive)) {
					this.toggleViewType(elem.dataset.show);

					if (this._callbacks.onViewChange) {
						this._callbacks.onViewChange(elem.dataset.show);
					}
				}

				return;
			}

			if (elem.matches(`.${this.selectors.closeBtn}`)) {
				this.reset();
				this.hide();

				return;
			}

			if (elem.matches(`.${this.selectors.applyBtn}`)) {
				if (this._callbacks.onApply) {
					this._callbacks.onApply(this);
				}

				return;
			}

			if (elem.matches(`.${this.selectors.sliderBtns}`)) {
				if (elem.classList.contains(this.selectors.sliderBtnDisabled)) {
					return;
				}

				if (this._callbacks.onSlide) {
					this._callbacks.onSlide(elem.dataset.direction);
				}
			}
		});

		this.html.addEventListener('keyup', (event) => {
			if (event.key === 'Escape') {
				this.hide();
			}
		});
	}
}
