import { dateAdd, dateAddMonths, dateAddWeeks, dateFormat, dateFormatYMD, dateGetDate, dateGetDay, dateGetYear, dateIsAfter, dateIsBefore, dateIsSameDay, dateIsToday, dateIsWeekend, dateParseISO } from '@utils/dateFns.js';
import { isMobileView, l, qs } from '@utils/toolbox';

const today = new Date(js_params.isoDates.today);
const _defaults = {
	dates: {
		today: js_params.isoDates.today,
		lastSelectableDay: dateFormatYMD(dateAdd(today, { days: 364 })),
		from: js_params.isoDates.fromIndex,
		to: js_params.isoDates.toIndex
	},
	dateRange: js_params.dateRange,
	currWeekNo: dateFormat(today, 'w'),
	currYear: dateFormat(today, 'yyyy'),
	elems: {
		datepickers: 'abDatePickers',
		datepicker: 'abDatePicker',
		daySpan: 'abDatePickers__dayspan',
		days: 'abDatePicker__days',
		inner: 'abDatePickers__inner',
		month: 'abDatePicker__month',
		row: 'abDatePicker__row',
		week: 'abDatePicker__week',
		weekCurrent: 'abDatePicker__week--current',
		weekPast: 'abDatePicker__week--past',
		day: 'abDatePicker__day',
		dayActive: 'abDatePicker__day--active',
		dayDepart: 'abDatePicker__day--depart',
		dayReturn: 'abDatePicker__day--return',
		dayDisabled: 'abDatePicker__day--disabled',
		dayRanged: 'abDatePicker__day--ranged',
		dayHoliday: 'abDatePicker__day--holiday',
		cell: 'abDatePicker__cell',
		cellWeekend: 'abDatePicker__cell--weekend',
		cellLast: 'abDatePicker__cell--last',
		label: 'abDatePicker__label',
		date: 'abDatePicker__date'
	}
};
export class DatePicker {
	constructor (opts) {
		this.searchBox = opts.searchBox;
		this.tripType = 1;
		this.focus = 'depart';
		this.departSelected = false;
		this.scrolling = false;
		this._callbacks = opts.callbacks || {};

		this.html = this.searchBox.dateSelector.html.getElementsByClassName(_defaults.elems.datepickers)[0];

		this.setHolidays();
		this.renderDaySpan();
		this.events();

		return this;
	}

	setHolidays () {
		if (document.documentElement.lang !== 'sv') {
			return;
		}

		this.holidays = new Set();

		if (window.holidayData) {
			const hols = window.holidayData;

			for (const hol in hols) {
				if (hols.hasOwnProperty(hol)) {
					this.holidays.add(hol);
				}
			}
		}
	}

	render (params) {
		const { tripType, elems, dates } = params;

		this.tripType = tripType;

		this.departField = elems.departField;
		this.returnField = null;

		this.departDate = dates.departDate;
		this.initialDepartDate = dates.departDate;
		this.returnDate = null;
		this.initialReturnDate = null;

		if (this.tripType === 1) {
			this.returnField = elems.returnField;
			this.returnDate = dates.returnDate;
			this.initialReturnDate = dates.returnDate;
		}

		this.resetScroll();
		this.renderDatepickers();
		this.scrollToDatepicker();

		return this;
	}

	departDateAfterReturn () {
		return dateIsAfter(new Date(this.departDate), new Date(this.returnDate));
	}

	departDateIsSameAsReturn () {
		return dateIsSameDay(new Date(this.departDate), new Date(this.returnDate));
	}

	scrollToDatepicker () {
		if (this.scrolling) {
			window.cancelAnimationFrame(this.scrolling);
		}

		this.scrolling = requestAnimationFrame(() => {
			const wrapper = this.html.getElementsByClassName(_defaults.elems.inner)[0];
			const activeDay = this.html.getElementsByClassName(_defaults.elems.dayActive)[0];
			if (!activeDay) {
				return;
			}
			const datepicker = activeDay.closest(`.${_defaults.elems.datepicker}`);

			if (isMobileView()) {
				wrapper.scrollTop = datepicker.offsetTop - 55;
			} else {
				wrapper.scrollLeft = datepicker.offsetLeft;
			}
		});
	}

	resetScroll () {
		this.html.getElementsByClassName(_defaults.elems.inner)[0].scrollTop = 0;

		return this;
	}

	renderWeekdays () {
		const weekdays = [...js_params.weekdays];
		let daySpans = '';

		for (let i = 0; i < weekdays.length; i++) {
			daySpans += `<span>${weekdays[i]}</span>`;
		}

		return daySpans;
	}

	renderDaySpan () {
		const daySpan = this.html.getElementsByClassName(_defaults.elems.daySpan)[0];

		daySpan.innerHTML = this.renderWeekdays();
	}

	renderMonth (obj) {
		return `
				<div class="${_defaults.elems.month}">
					<span>${obj.month} ${obj.year}</span>
				</div>
			`;
	}

	renderWeekDay (obj) {
		const { date, weekNo } = obj;
		let cellStyle = _defaults.elems.cell;
		let weekStyle = _defaults.elems.week;

		const dateObject = dateParseISO(date);

		const dateFormatted = dateFormatYMD(dateObject);
		const dateFormattedLong = dateFormat(dateObject, 'd MMMM yyyy');
		const day = dateGetDate(dateObject);
		const dayOfWeek = dateGetDay(dateObject);
		const year = dateGetYear(dateObject);
		const isWeekend = dateIsWeekend(dateObject);

		if (isWeekend) {
			cellStyle += ` ${_defaults.elems.cellWeekend}`;
		}

		if (dayOfWeek === 0) {
			cellStyle += ` ${_defaults.elems.cellLast}`;
		}

		if (weekNo === _defaults.currWeekNo && year === _defaults.currYear) {
			weekStyle = `${_defaults.elems.week} ${_defaults.elems.weekCurrent}`;
		} else if (weekNo < _defaults.currWeekNo) {
			weekStyle = `${_defaults.elems.week} ${_defaults.elems.weekPast}`;
		}

		return `
<div class="${cellStyle}">
	<div class="${weekStyle}">
		<span>${weekNo}</span>
	</div>
	<div class="${_defaults.elems.day}" data-date="${dateFormatted}" aria-label="${dateFormattedLong}" role="gridcell">
		<span class="${_defaults.elems.label}"></span>
		<span class="${_defaults.elems.date}">${day}</span>
	</div>
</div>
			`;
	}

	renderDatepickers () {
		const dates = _defaults.dateRange;
		let html = ``;
		console.log('render date pickers');

		for (const year in dates) {
			if (dates.hasOwnProperty(year)) {
				const months = dates[year].months;

				for (const month in months) {
					if (months.hasOwnProperty(month)) {
						const weeks = months[month].weeks;

						html += `<div class="${_defaults.elems.datepicker}">`;

						html += `
								<div class="${_defaults.elems.days}">
									${this.renderWeekdays()}
								</div>
							`;

						html += this.renderMonth({
							year: dateGetYear(new Date(year, month - 1, 1)),
							month: dateFormat(new Date(year, month - 1, 1), 'MMM')
						});

						for (const week in weeks) {
							if (weeks.hasOwnProperty(week)) {
								const days = weeks[week].days;

								html += `<div class="${_defaults.elems.row}">`;

								for (const day in days) {
									if (days.hasOwnProperty(day)) {
										html += this.renderWeekDay({ date: days[day], weekNo: weeks[week].week });
									}
								}

								html += `</div>`;
							}
						}

						html += `</div>`;
					}
				}
			}
		}

		this.html.getElementsByClassName(_defaults.elems.inner)[0].innerHTML = html;

		this.setDayStyles();

		if (document.body.classList.contains('tabmode')) {
			qs(`.${_defaults.elems.dayActive}`).focus();
		}
	}

	setDayLabel (dayElem) {
		const elemLabel = dayElem.getElementsByClassName(_defaults.elems.label)[0];
		const elemDate = dayElem.dataset.date;

		if (elemLabel.firstChild) {
			elemLabel.innerText = '';
		}

		if (_defaults.dates.today === elemDate) {
			elemLabel.textContent = l('global.simplePhrases.today');
		}

		if (this.departDate === elemDate) {
			elemLabel.textContent = l('global.simplePhrases.depart');

			this.selectedDepartDayCell = dayElem;
		}

		if (this.tripType === 1) {
			if (this.returnDate === elemDate) {
				this.selectedReturnDayCell = dayElem;

				if (this.departDateIsSameAsReturn()) {
					elemLabel.textContent = l('global.simplePhrases.depRet');
				} else {
					elemLabel.textContent = l('global.simplePhrases.return');
				}
			}
		}
	}

	setDayStyles () {
		const today = new Date(js_params.isoDates.today);
		const lastSelectableDay = new Date(_defaults.dates.lastSelectableDay);
		const days = this.html.getElementsByClassName(_defaults.elems.day);

		let departIndex = 0;
		let returnIndex = 0;

		for (let i = 0; i < days.length; i++) {
			const dayElem = days[i];
			const elemDate = dayElem.dataset.date;
			const elemObjDate = new Date(elemDate);

			dayElem.classList.remove(_defaults.elems.dayActive);
			dayElem.classList.remove(_defaults.elems.dayDepart);
			dayElem.classList.remove(_defaults.elems.dayReturn);
			dayElem.classList.remove(_defaults.elems.dayRanged);

			// If current dayElem is before today or after the last selectable day
			// then add the dayDisabled class

			if (!dateIsToday(elemObjDate) && (dateIsBefore(elemObjDate, today) || dateIsAfter(elemObjDate, lastSelectableDay))) {
				dayElem.classList.add(_defaults.elems.dayDisabled);
			} else {
				dayElem.tabIndex = 0;
			}

			if (this.departDate === elemDate) {
				dayElem.classList.add(_defaults.elems.dayActive);
				dayElem.classList.add(_defaults.elems.dayDepart);

				departIndex = i;
			}

			if (this.tripType === 1) {
				if (this.returnDate === elemDate) {
					dayElem.classList.add(_defaults.elems.dayActive);
					dayElem.classList.add(_defaults.elems.dayReturn);

					returnIndex = i;
				}
			}

			if (this.holidays) {
				const isHoliday = this.holidays.has(elemDate);

				if (isHoliday) {
					dayElem.classList.add(_defaults.elems.dayHoliday);
				}
			}

			this.setDayLabel(dayElem);
		}

		for (let i = 0; i < days.length; i++) {
			const dayElem = days[i];

			if (i > departIndex && i < returnIndex) {
				dayElem.classList.add(_defaults.elems.dayRanged);
			}
		}
	}

	returnDayClick (day) {
		if (this.focus === 'depart') {
			this.departDate = day.dataset.date;

			this.departSelected = true;

			this.focus = 'return';

			if (this.departDateAfterReturn()) {
				this.returnDate = this.departDate;
			}

			if (this._callbacks.onDepartDatePicked) {
				this._callbacks.onDepartDatePicked(this);
			}
		} else if (this.focus === 'return') {
			if (dateIsBefore(new Date(day.dataset.date), new Date(this.departDate))) {
				this.departSelected = true;
				this.departDate = day.dataset.date;
				this._callbacks.onDepartDatePicked(this);
				this.setDayStyles();
				return;
			}

			this.returnDate = day.dataset.date;

			if (this._callbacks.onReturnDatePicked) {
				this._callbacks.onReturnDatePicked(this);
			}
		}

		this.setDayStyles();
	}

	singleDayClick (day) {
		this.departDate = day.dataset.date;

		if (this._callbacks.onDepartDatePicked) {
			this._callbacks.onDepartDatePicked(this);
		}

		this.setDayStyles();
	}

	dayClicked (day) {
		if (day.classList.contains(_defaults.elems.dayDisabled)) {
			return false;
		}

		if (this.tripType === 1) /* return */ {
			this.returnDayClick(day);
		} else {
			this.singleDayClick(day);
		}
	}

	resetDates () {
		this.departDate = this.initialDepartDate;

		if (this.tripType === 1) {
			this.returnDate = this.initialReturnDate;
		}
	}

	events () {
		const inner = this.html.getElementsByClassName(_defaults.elems.inner)[0];
		let scrollTimer;

		this.html.addEventListener('click', (event) => {
			const elem = event.target;

			if (!elem.matches(`.${_defaults.elems.day}`)) {
				return;
			}

			this.dayClicked(event.target);
		}, false);

		if (this._callbacks.onScroll) {
			inner.addEventListener('scroll', (event) => {
				if (scrollTimer) {
					clearTimeout(scrollTimer);
				}

				scrollTimer = setTimeout(() => this._callbacks.onScroll(), 120);
			}, false);
		}


		this.html.addEventListener('keyup', (event) => {
			const elem = event.target;

			switch (event.key) {
				case 'Enter':
				case 'Space':
					elem.click();
					// Focus on next day after click
					if (this.tripType === 1) {
						const nextDay = dateAdd(new Date(elem.dataset.date), { days: 1 });
						qs(`[data-date="${dateFormat(nextDay, 'yyyy-MM-dd')}"]`).focus();
					}

					break;
				case 'ArrowLeft':
					// Select last month
					if (document.activeElement.dataset.date) {
						const lastMonth = dateAddMonths(dateParseISO(document.activeElement.dataset.date), -1);
						qs(`[data-date="${dateFormat(lastMonth, 'yyyy-MM-dd')}"]`).focus();
					}
					break;
				case 'ArrowRight':
					// Select next month
					if (document.activeElement.dataset.date) {
						const nextMonth = dateAddMonths(dateParseISO(document.activeElement.dataset.date), 1);
						qs(`[data-date="${dateFormat(nextMonth, 'yyyy-MM-dd')}"]`).focus();
					}
					break;
				case 'ArrowUp':
					// Select last week
					if (document.activeElement.dataset.date) {
						const lastWeek = dateAddWeeks(dateParseISO(document.activeElement.dataset.date), -1);
						qs(`[data-date="${dateFormat(lastWeek, 'yyyy-MM-dd')}"]`).focus();
					}
					break;
				case 'ArrowDown':
					// Select next week
					if (document.activeElement.dataset.date) {
						const nextWeek = dateAddWeeks(dateParseISO(document.activeElement.dataset.date), 1);
						qs(`[data-date="${dateFormat(nextWeek, 'yyyy-MM-dd')}"]`).focus();
					}
					break;
				case 'Escape':
					// Close depending on focus
					// this.focus === 'depart' ? this.onDepartDatePicked(this) : this.onReturnDatePicked(this);

					break;
			}
		});
	}
}
